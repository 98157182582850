import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Programs from './pages/Programs';
import Error from './pages/Error';
import TaskDetails from './components/TaskDetails';
import Profile from './components/Profile';
import Community from './pages/Community/Community';
import Chat from './pages/Community/Chat';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import ScrollToTop from './pages/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      {/* Add top padding to the main content area to account for the fixed NavBar */}
      <div className="pt-20"> {/* Adjust this value based on the height of your NavBar */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Programs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/community" element={<Community />} />
          <Route path="/chat/:id" element={<Chat />} /> {/* Added Chat route */}
          <Route path="/task/:id" element={<TaskDetails />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
