// import React, { useEffect, useState } from "react";
// import ChatService from "../../services/ChatServices";

// const Community = () => {
//   const [combinedChats, setCombinedChats] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedChat, setSelectedChat] = useState(null);

//   useEffect(() => {
//     const fetchChats = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const chats = await ChatService.getCombinedChats();
//         console.log("Fetched combined chats:", chats);
//         setCombinedChats(chats);
//       } catch (err) {
//         setError("Failed to fetch chats. Please try again later.");
//         console.error("Error fetching combined chats:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchChats();
//   }, []);

//   const handleChatClick = (chat) => {
//     const chatId = chat.chatGroup?.id; // Use `chatGroup.id` for groups
//     const chatType = chat.chatGroup ? "Group" : "Personal";
//     if (chatId) {
//       console.log(`Selected Chat: ${chatId} (${chatType})`);
//       setSelectedChat({ id: chatId, type: chatType, name: chat.chatGroup?.groupName });
//     } else {
//       console.error("Chat ID is undefined.");
//     }
//   };

//   if (loading) return <div>Loading chats...</div>;
//   if (error) return <div className="error-message">{error}</div>;

//   return (
//     <div className="community-container">
//       <h2>Community Chats</h2>
//       <div className="combined-chats">
//         {combinedChats.length === 0 ? (
//           <p>No chats available.</p>
//         ) : (
//           combinedChats.map((chat, index) => (
//             <div
//               key={`chat-${chat.chatGroup?.id || index}`} // Ensure unique keys
//               className="chat-item"
//               onClick={() => handleChatClick(chat)}
//             >
//               <h4>{chat.chatGroup?.groupName || "Unnamed Group"}</h4>
//               <p>{chat.lastMessage || "No messages yet"}</p>
//             </div>
//           ))
//         )}
//       </div>

//       {selectedChat && (
//         <div className="selected-chat">
//           <h3>Selected Chat</h3>
//           <p>
//             Chat ID: {selectedChat.id} ({selectedChat.type})
//           </p>
//           <p>Chat Name: {selectedChat.name}</p>
//           {/* Add chat message rendering or navigation here */}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Community;



import React, { useEffect, useState } from "react";
import ChatService from "../../services/ChatServices";
import { useNavigate } from "react-router-dom";

const Community = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChats = async () => {
      setLoading(true);
      setError(null);
      try {
        const combinedChats = await ChatService.getCombinedChats();
        setChats(combinedChats);
      } catch (err) {
        console.error("Error fetching chats:", err);
        setError("Failed to fetch chats. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, []);

  const handleChatClick = (chat) => {
    if (!chat) {
      console.warn("Invalid chat object passed to handleChatClick.");
      return;
    }

    const isGroup = !!chat.chatGroup;
    const chatId = isGroup ? chat.chatGroup.id : chat.id;
    const chatName = isGroup ? chat.chatGroup.groupName : chat.userName || "Personal Chat";

    navigate(`/chat/${chatId}`, {
      state: {
        chatName,
        chatType: isGroup ? "group" : "personal",
      },
    });
  };

  if (loading) return <div>Loading chats...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="community-container">
      <div className="groups-container">
        {chats.length === 0 ? (
          <p>No chats available.</p>
        ) : (
          chats.map((chat, index) => {
            const isGroup = !!chat.chatGroup;
            const chatName = isGroup
              ? chat.chatGroup.groupName
              : chat.userName || "Personal Chat";
            const lastMessage = chat.lastMessage?.content || "No messages yet";

            return (
              <div
                key={`${isGroup ? "group" : "personal"}-${chat.chatGroup?.id || chat.id}-${index}`}
                className="groups-item"
                onClick={() => handleChatClick(chat)}
              >
                <h4>{chatName}</h4>
                <p>{lastMessage}</p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Community;
