import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import UserService from '../services/UserService';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in
    const checkLoginStatus = async () => {
      const loggedIn = await UserService.isUserLoggedIn();
      setIsLoggedIn(loggedIn);
    };

    checkLoginStatus();
  }, []);

  // If the user is logged in, do not render the header
  if (isLoggedIn) {
    return null;
  }

  return (
    <div className="header-container">
      {/* Title and Description */}
      <div className="header-title-container">
        <h1>Prep4USA</h1>
        <p>Empowering your journey to study in the USA</p>
      </div>

      {/* Sliding Box */}
      <div className="header-slider-container">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          className="header-swiper"
        >
          <SwiperSlide>
            <div className="header-swiper-slide">
              <h3>Comprehensive Task Management</h3>
              <p>Personalized task lists and tracking for each step.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="header-swiper-slide">
              <h3>Get to know your Peers</h3>
              <p>Preference based Roommate matching.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="header-swiper-slide">
              <h3>Networking and Community</h3>
              <p>Connect with peers and alumni for guidance.</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Header;
