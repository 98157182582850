// import axios from "axios";
// import UserService from "./UserService";

// class ChatService {
//   // Base API URL for Chat Services
//   static API_BASE_URL = `${UserService.API_BASE_URL}/messages`;

//   /**
//    * Fetch group chats
//    */
//   static async  getGroupChats() {
//     try {
//       // Fetch user's universities
//       const userUniversities = await UserService.getUserUniversities();
//       console.log("Fetched user universities:", userUniversities);

//       // Validate the universities and collegeId
//       const collegeId = userUniversities?.[0]?.collegeId;
//       if (!collegeId) {
//         throw new Error("College ID not available.");
//       }

//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log("Fetching group chats for collegeId:", collegeId);

//       // Make API call to fetch group headers
//       const response = await axios.get(`${this.API_BASE_URL}/group/headers?collegeId=${collegeId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       console.log("Group chats fetched successfully:", response.data);
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching group chats:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Fetch personal chats
//    */
//   static async getPersonalChats() {
//     try {
//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log("Fetching personal chats...");

//       // Make API call to fetch personal headers
//       const response = await axios.get(`${this.API_BASE_URL}/personal/headers`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       console.log("Personal chats fetched successfully:", response.data);
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching personal chats:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Fetch combined group and personal chats
//    */
//   static async getCombinedChats() {
//     try {
//       console.log("Fetching combined group and personal chats...");
//       const groupChats = await this.getGroupChats();
//       const personalChats = await this.getPersonalChats();
//       const combinedChats = [...groupChats, ...personalChats];
//       console.log("Combined chats fetched successfully:", combinedChats);
//       return combinedChats;
//     } catch (error) {
//       console.error("Error fetching combined chats:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Fetch messages for a group
//    */
//   static async getGroupMessages(groupId, maxMessageId = null) {
//     try {
//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log(`Fetching messages for groupId: ${groupId}`);

//       // Make API call to fetch group messages
//       const response = await axios.get(`${this.API_BASE_URL}/group`, {
//         headers: { Authorization: `Bearer ${token}` },
//         params: { groupId, maxMessageId },
//       });

//       console.log("Group messages fetched successfully:", response.data);
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching group messages:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Fetch messages for a personal chat
//    */
//   static async getPersonalMessages(otherUserId) {
//     try {
//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log(`Fetching messages for personal chat with userId: ${otherUserId}`);

//       // Make API call to fetch personal messages
//       const response = await axios.get(`${this.API_BASE_URL}/personal`, {
//         headers: { Authorization: `Bearer ${token}` },
//         params: { otherUserId },
//       });

//       console.log("Personal messages fetched successfully:", response.data);
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching personal messages:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Send a message
//    */
//   static async sendMessage(messageData) {
//     try {
//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log("Sending message:", messageData);

//       // Make API call to send message
//       const response = await axios.post(`${this.API_BASE_URL}/send`, messageData, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       console.log("Message sent successfully:", response.data);
//       return response.data;
//     } catch (error) {
//       console.error("Error sending message:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Mark group messages as read
//    */
//   static async reportGroupMessageRead(groupId, lastReadMessageId) {
//     try {
//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log(`Reporting group messages as read for groupId: ${groupId}`);

//       // Make API call to report group messages read
//       await axios.post(
//         `${this.API_BASE_URL}/group/read`,
//         { groupId, lastReadMessageId },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       console.log("Group messages marked as read successfully.");
//     } catch (error) {
//       console.error("Error marking group messages as read:", error.message);
//       throw error;
//     }
//   }

//   /**
//    * Mark personal messages as read
//    */
//   static async reportPersonalMessageRead(otherUserId, lastSeenMessageId) {
//     try {
//       // Fetch token
//       const token = await UserService.getUserToken();
//       if (!token) {
//         throw new Error("User token not available.");
//       }

//       console.log(`Reporting personal messages as read for userId: ${otherUserId}`);

//       // Make API call to report personal messages read
//       await axios.post(
//         `${this.API_BASE_URL}/personal/read`,
//         { otherUserId, lastSeenMessageId },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       console.log("Personal messages marked as read successfully.");
//     } catch (error) {
//       console.error("Error marking personal messages as read:", error.message);
//       throw error;
//     }
//   }
// }

// export default ChatService;


import axios from "axios";
import UserService from "./UserService";

class ChatService {
  // Base API URL for Chat Services
  static API_BASE_URL = `${UserService.API_BASE_URL}/messages`;

  /**
   * Fetch group chats
   */
  static async getGroupChats() {
    try {
      const userUniversities = await UserService.getUserUniversities();
      console.log("Fetched user universities:", userUniversities);

      const collegeId = userUniversities?.[0]?.collegeId;
      if (!collegeId) {
        throw new Error("College ID not available.");
      }

      const token = await UserService.getUserToken();
      if (!token) {
        throw new Error("User token not available.");
      }

      console.log("Fetching group chats for collegeId:", collegeId);

      const response = await axios.get(`${this.API_BASE_URL}/group/headers`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { collegeId },
      });

      console.log("Group chats fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching group chats:", error.message);
      throw error;
    }
  }

  /**
   * Fetch messages for a group
   */
  static async getGroupMessages(groupId, maxMessageId = 0) {
    try {
      const token = await UserService.getUserToken();
      if (!token) {
        throw new Error("User token not available.");
      }

      console.log(`Fetching messages for groupId: ${groupId}`);

      // const response = await axios.get(`${this.API_BASE_URL}/group`, {
      //   headers: { Authorization: `Bearer ${token}` },
      //   params: { groupId, maxMessageId },
      // });
      const response = await axios.get(`${this.API_BASE_URL}/group`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { 
          groupId, 
          maxMessageId: maxMessageId || 0 // Default to 0 if not provided
        },
      });
      

      console.log("Group messages fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching group messages:", error.message);
      throw error;
    }
  }

  /**
   * Fetch personal chats
   */
  static async getPersonalChats() {
    try {
      const token = await UserService.getUserToken();
      if (!token) {
        throw new Error("User token not available.");
      }

      console.log("Fetching personal chats...");

      const response = await axios.get(`${this.API_BASE_URL}/personal/headers`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Personal chats fetched successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching personal chats:", error.message);
      throw error;
    }
  }

  /**
   * Fetch combined group and personal chats
   */
  static async getCombinedChats() {
    try {
      console.log("Fetching combined group and personal chats...");
      const groupChats = await this.getGroupChats();
      const personalChats = await this.getPersonalChats();
      const combinedChats = [...groupChats, ...personalChats];
      console.log("Combined chats fetched successfully:", combinedChats);
      return combinedChats;
    } catch (error) {
      console.error("Error fetching combined chats:", error.message);
      throw error;
    }
  }

  /**
   * Send a message
   */
  static async sendMessage(messageData) {
    try {
      const token = await UserService.getUserToken();
      if (!token) {
        throw new Error("User token not available.");
      }

      console.log("Sending message:", messageData);

      const response = await axios.post(`${this.API_BASE_URL}/send`, messageData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Message sent successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error.message);
      throw error;
    }
  }

  /**
   * Report group messages as read
   */
  static async reportGroupMessageRead(groupId, lastReadMessageId) {
    try {
      const token = await UserService.getUserToken();
      if (!token) {
        throw new Error("User token not available.");
      }

      console.log(`Reporting group messages as read for groupId: ${groupId}`);

      await axios.post(
        `${this.API_BASE_URL}/group/read`,
        { groupId, lastReadMessageId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Group messages marked as read successfully.");
    } catch (error) {
      console.error("Error marking group messages as read:", error.message);
      throw error;
    }
  }
}

export default ChatService;
